import { isFocusable } from "@testing-library/user-event/dist/utils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import bcs from "./bcs";
import Act from "./gcomp/act";
import Dish from "./gcomp/dish";
import LDish from "./gcomp/ldish";
import LTable from "./gcomp/ltables";
import Msg from "./gcomp/msg";
import Spin from "./gcomp/spin";
import Summary from "./gcomp/summary";
import Bc_Tab from "./gcomp/tab";
import UpStatus from "./gcomp/up_status";
import { groupBy, inSameCommand, IN_DEBUG, IN_MODIF, IS_FS, JOIN_CMD, MIDD_URL, NOREP, PLAY_AUDIO, RC, SC, set_dev_id, set_in_modif, set_is_fs, set_noreps, set_pl_audio, set_stat_nov, set_tmp_stat, set_wkp_nov, STATUS, STAT_NOV, TMP_STAT, WKP_NOV, f_render, F_RENDER, N_VAI, set_n_vai, set_del_alert, DEL_ALERT, ALERTS, set_glb_txns, ALERT_RUN, set_alert_run, set_group_clr, GROUP_COLOR, DEV_ID, set_del_msg_to_read, DEL_MSG_TO_READ } from "./glb";
import { epson, esc_pos } from "./printers/protocols";
import { get_devices, remove_from_work_in_progress, save_work_in_progress } from "./reuse";
import VaiAnim from "./gcomp/vai_anim";
import Alerts from "./gcomp/alerts";
import { start_runtime_alert } from "./runtime_alert";
import Chat from "./gcomp/chat";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

let hookCommands = [];
let callbackVaiAnim;

let timer = 120;
const View2 = ({txns}) => {

    const [c_view, set_c_view]                          = useState(null);
    const [filter_for, set_filter_for]                  = useState(null);
    const [filter_id, set_filter_id]                    = useState(null);
    const [all_dishes, set_all_dishes]                  = useState([]);
    const [all_tables, set_all_tables]                  = useState([]);
    const [table_dish, set_table_dish]                  = useState('display_dish');
    const [act_dishes, set_act_dishes]                  = useState([]);
    const [in_select_all, set_in_select_all]            = useState(false);
    const [display_spin, set_display_spin]              = useState(false);
    const [force_render, set_force_render]              = useState(new Date().getTime());
    const [number_of_dish, set_number_of_dish]          = useState(0);
    const [display_summary, set_display_summary]        = useState(false);
    const [summary_rows, set_summary_rows]              = useState([]);
    const [devices, set_devices]                        = useState([]);
    const [filterOnlyVai, setFilterOnlyVai]             = useState(false);
    const [msgToRead, setMsgToRead]                     = useState(0);
    const [displayChat, setDisplayChat]                 = useState(false);
    const navigate                                      = useNavigate();

    useEffect(() => {

        if(txns){

            const _all_dishes = [];
            const _all_tables = [];

            txns.forEach((item) => {    
            
                const table_id = item.id;
                item.lines.forEach((line) => {
                    _all_dishes.push(line);
                    line.table_code = table_id;
                });

                _all_tables.push(table_id); 
            });

            // Imposta il primo carry, dai carry
            if(localStorage.getItem('monitor') == "command" || localStorage.getItem('monitor') == "table"){
                txns.forEach((txn) => {
                    const filteredCarries = txn.lines.filter(l => l.is_carry && l.status == c_view);
                    if(filteredCarries && filteredCarries.length > 0){
                        filteredCarries[0].is_first_carry = true;
                    }
                });
            }

            const dishes_with_timestamp = (_all_dishes ?? []).filter(item => item.timestamp);
            dishes_with_timestamp.sort((a, b) => b.original_elapsed - a.original_elapsed);

            set_number_of_dish(calculate_number_of_dishes());

            set_all_dishes(dishes_with_timestamp);
            set_all_tables(_all_tables);
            set_glb_txns(txns);

        }

    }, [txns]);

    useEffect(() => {
        //if(c_view == "da_fare"){
            set_number_of_dish( calculate_number_of_dishes() );
        //}
    }, [c_view]);

    const calculate_number_of_dishes = () => {
        let _number_of_dishes = 0;
        txns.forEach((item) => {
            const indexNoCarry = item.lines.findIndex(l => !l.is_carry);
            if(indexNoCarry == -1){
                return;
            }
            item.lines.filter(l => !l.is_carry).forEach((line) => {
                if(line.status == c_view){
                    _number_of_dishes += (line.quantity - (line.done ?? 0));
                }
            })
        });
        return _number_of_dishes;
    };

    const wrap_transaction_as_table_or_command = (txns, type = 'table', filter_for = null, filter_id = null, order_type_debug = null) => {
        
        if(type == 'table'){

            // Colorazione dei vai con
            txns.forEach(cmd => {
                const lines = cmd.lines.filter(i => i.status == c_view);
                if(lines && lines.length > 0){
                    let fVai = lines[0].vai_con;
                    for(let l = 0; l < lines.length; l++){
                        if(lines[l].vai_con == fVai){
                            if(!lines[l].vai_con){
                                lines[l].highlight = true;
                            }else{
                                if(lines[l].evasione_vai){
                                    lines[l].highlight = true;
                                }
                            }
                        }
                    }
                }
            });

            if(filter_for && filter_id){
                if(filter_for == 'dish'){
                    let new_txns = [];
                    txns.forEach(t => {
                        if((t.lines ?? []).filter(i => i.ext_code == filter_id.toString() && c_view == i.status).length > 0){
                            new_txns.push(t);
                        }
                    });
                    return new_txns;
                }else{
                    return txns.filter(i => i.id.toString() == filter_id.toString());
                }
            }

            return txns;
        }else{
            
            let commands = [];

            // Per tutti quelli che hanno il secondo timer considera:
            // - quelli che hanno uno stesso gruppo di vai con nello stesso stato != da fare
            // - dal risultato di sopra prendi il valore più grande e conteggia i tempi
            const useTwoTimer = localStorage.getItem('use_two_timer') == "1";
            if(useTwoTimer){

                txns.forEach(i => {

                    const hasTimer = i.lines.findIndex(i => i.vai_con) > -1;
                    if(hasTimer){
                        const groups = [];
                        for(let l = 0; l < i.lines.length; l++){
                            let _vai_con = "--SENZA ATTESA--";
                            if(i.lines[l].vai_con){
                                _vai_con = i.lines[l].vai_con;
                            }
                            const index = groups.findIndex(i => i.status == _vai_con);
                            const line = i.lines[l];
                            if(index > -1){
                                groups[index].pos.push({id: line.id, status: line.status, moved_at: line.moved_in_new_status_at});
                            }else{
                                groups.push({
                                    status: _vai_con,
                                    pos: [
                                        {id: line.id, status: line.status, moved_at: line.moved_in_new_status_at}
                                    ]
                                })
                            }
                        }
                        i.groups_timing = groups;
                    }

                });

                // Effettua esclusioni come sopra indicato
                txns.filter(i => i.groups_timing && i.groups_timing.length > 0)
                .forEach(t => {
                    
                    // Escludi tutti quelli che hanno almeno uno stato differente
                    const times_second_timer = [];
                    t.groups_timing.forEach(g => {
                        const indexStatusNotUse = g.pos.findIndex( p => p.status == "da_fare" || !p.moved_at );
                        if(indexStatusNotUse == -1){
                            g.pos.forEach(p => {
                                times_second_timer.push(p.moved_at);
                            });
                        }
                    });
                    if(times_second_timer && times_second_timer.length > 0){
                        
                        // Ottieni la data più grande
                        times_second_timer.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
                        const last = times_second_timer[times_second_timer.length - 1];
                        const elapsed = new Date() - new Date(last);
                        const elapsed_minutes = Math.floor( ( (elapsed < 0 ? 0 : elapsed) / 1000) / 60 );
                        t.second_waiting_time = elapsed_minutes;
                        t.command_timer = true;

                    }else{
                        t.second_waiting_time = 0;
                        t.command_timer = false;
                    }

                });

            }

            txns.forEach((txn) => {
                
                txn.lines.forEach((line) => {
                    const index = commands.findIndex(i => 
                        (i.bill_id == line.bill_id) 
                        && 
                        (i.id.toString() == txn.id.toString()) 
                        && 
                        (
                            localStorage.getItem('group_command_for_minute') == "1" ? 
                                (line.date_get == i.date_get)
                            :
                                (line.nsessione == i.nsessione)
                        )
                    );
                    if(index > -1){
                        commands[index].lines.push(line);
                    }else{
                        if(txn.display !== -1){
                            commands.push({
                                id: txn.id.toString(),
                                bill_id: line.bill_id,
                                date_get: line.date_get,
                                nsessione: line.nsessione,
                                is_delivery: txn.is_delivery,
                                delivery_customer: txn.delivery_customer,
                                hour_delivery: txn.hour_delivery,
                                delivery_type: txn.delivery_type,
                                number_of_covers: txn.number_of_covers,
                                command_timer: useTwoTimer ? txn.command_timer : false,
                                second_waiting_time: useTwoTimer ? txn.second_waiting_time : 0,
                                critical_status: txn.critical_status,
                                id_ticket: txn.id_ticket,
                                cmd_ticket: txn.cmd_ticket,
                                lines: [line]
                            });
                        }
                    }
                });
            });


            commands.sort((a, b) => new Date(b.date_get) - new Date(b.date_get));
            commands.forEach((c) => {
                const elapsed = new Date() - new Date(c.date_get);
                const elapsed_minutes = Math.floor( ( (elapsed < 0 ? 0 : elapsed) / 1000) / 60 );
                c.waiting_time = elapsed_minutes;
            });

            let index = 0.000000;
            commands.forEach(i => {
                i.waiting_time += index;
                index += 0.000001;
            });

            if(filter_for && filter_id){
                if(filter_for == 'dish'){
                    let new_commands = [];
                    commands.forEach(c => {
                        if((c.lines ?? []).filter(d => d.ext_code == filter_id.toString() && d.status == c_view).length > 0){
                            new_commands.push(c);
                        }
                    });
                    commands = [...new_commands];
                }else{
                    return commands.filter(i => i.id == filter_id.toString());
                }
            }

            let cmds = [];
            commands.forEach(c => {

                const index = c.lines.findIndex(i => i.status == c_view && !i.is_carry);
                if(index > -1){
                    cmds.push(c);
                }

            });

            hookCommands = cmds;

            // Conteggia date evasioni VAI
            let numberOfVai = 0;
            txns.forEach(h => {
                const len = h.lines.filter(i => i.evasione_vai).length;
                numberOfVai += len;
            });

            let canSound = true;
            if(N_VAI == -1){
                canSound = false;
            }
            if(N_VAI != numberOfVai){
                console.log("There is new VAI");
                set_n_vai(numberOfVai);
                if(canSound){
                    callbackVaiAnim(true);
                    document.querySelector('#audio_bell').play();
                    const btnVaiCon = document.querySelector('#btn_vai_con');
                    if(btnVaiCon){
                        btnVaiCon.style.setProperty('color', "red");
                        btnVaiCon.style.setProperty('background-color', "yellow");
                    }
                }
            }

            // Colorazione dei vai con
            cmds.forEach(cmd => {
                const lines = cmd.lines.filter(i => i.status == c_view);
                if(lines && lines.length > 0){
                    let fVai = lines[0].vai_con;
                    for(let l = 0; l < lines.length; l++){
                        if(lines[l].vai_con == fVai){
                            if(!lines[l].vai_con){
                                lines[l].highlight = true;
                            }else{
                                if(lines[l].evasione_vai){
                                    lines[l].highlight = true;
                                }
                            }
                        }
                    }
                }
            });

            // Se attiva gestione colorazione criticità comanda
            try{
                if(localStorage.getItem('active_critical_command') == "1"){
                    
                    const colors = JSON.parse( (localStorage.getItem('critical_command') ?? "[]") );
                    if(colors.length > 0){
                        colors.forEach(item => {
                            const start = item.start_from;
                            const end = item.end_to;
                            const color = item.color;
                            if( (start || start == 0) && (end || end == 0) && color){
                                if(!isNaN(start) && !isNaN(end)){
                                    cmds.forEach(c => {
                                        c.lines.filter(l => (l.elapsed_minutes || l.elapsed_minutes == 0) && l.elapsed_minutes >= start && l.elapsed_minutes <= end).forEach(line => {
                                            line.override_color = color;
                                        });
                                    });
                                }
                            }
                        });
                    }

                    if(GROUP_COLOR){
                        let filtered = [];
                        cmds.forEach(c => {
                            const index = c.lines.findIndex(l => l.override_color == GROUP_COLOR);
                            if(index > -1){
                                filtered.push(c);
                            }
                        });
                        cmds = JSON.parse( JSON.stringify( filtered ) );
                    }

                }   
            }catch(_){}

            // Se ha id ticket, controlla se le linee successive alla 0 hanno ID diverso => PARTI DI CONTO
            try{
                cmds.filter(c => c.id_ticket).forEach(c => {
                    let id_ticket = c.id_ticket;
                    c.lines.forEach(l => {
                        l.id_ticket = id_ticket;
                    });
                });
            }catch(_){}

            try{
                if(order_type_debug == "by_id"){
                    cmds.sort((a, b) => parseInt(a.bill_id) - parseInt(b.bill_id));
                }
            }catch(_){}

            try{ // Rimuovi id duplicati

            }catch(_){}

            return cmds;
        }
    };

    const dish_tap = (item) => {

        const _act_dishses = [...act_dishes];

        if(_act_dishses.length > 0){
            if(item.work_in_progress){
                if(_act_dishses.filter(item => !item.work_in_progress).length > 0) return;
            }else{
                if(_act_dishses.filter(item => item.work_in_progress).length > 0) return;
            }
        }

        const index = _act_dishses.findIndex(d => d.id == item.id);
        if(index == -1){
            _act_dishses.push(item);
        }else{
            _act_dishses.splice(index, 1);
        }

        const dish_to_pulse = [];
        _act_dishses.forEach((item) => {
            if(item.ext_code){
                const filtered = all_dishes.filter(d => d.ext_code == item.ext_code);
                if(filtered.length > 0){
                    dish_to_pulse.push(item);
                    filtered.forEach((f) => dish_to_pulse.push(f));
                }
            }
        });

        const bc_pulse = new CustomEvent('bc:pulse', {detail: dish_to_pulse});
        dispatchEvent(bc_pulse);

        set_act_dishes(_act_dishses);

        set_in_modif( (_act_dishses ?? []).length > 0 );

    };

    const is_dish_active = (item) => {
        return act_dishes.findIndex(d => d.id.toString() == item.id.toString()) > -1 ? true : false;
    };

    const  clean_active_dishes = () => set_act_dishes([]);

    const ticket_manager_stepper = async (is_for_close = true) => {

        // Se attiva gestione ticket, controlla i passaggi di stato
        try{
            const enabled_ticket_list = localStorage.getItem("enabled_ticket_list") ?? "0";
            if(enabled_ticket_list == "1"){

                // Ottieni status bidone
                const index_trash = is_for_close ? STATUS.findIndex(s => s.visible == 0) : STATUS.findIndex(s => s.to_retire == 1 || s.automatic_cancel == 1);
                if(index_trash > -1){
                    const enum_status = STATUS[index_trash]['status_enum'];
                    if(enum_status){
                        const txns_closed = txns.filter(t => t.id_ticket);
                        const ids_to_close = [];
                        for(let t = 0; t < txns_closed.length; t++){
                            const lines = txns_closed[t].lines ?? [];
                            const index_not_closed = lines.findIndex(l => l.status != enum_status);
                            if(index_not_closed == -1){
                                ids_to_close.push(
                                    parseInt(
                                        txns_closed[t].id_ticket.toString()
                                    )
                                );
                            }
                        }
                        if(ids_to_close && ids_to_close.length > 0){
                            
                            const ids = JSON.stringify( ids_to_close );
                            
                            // Cancellazione automatica
                            try{
                                //if(!is_for_close){
                                    let status = STATUS[index_trash];
                                    if(status && status.automatic_cancel == 1){ 
                                        await fetch(
                                            `${MIDD_URL}/api/v1/bcv/automatic_cancel?restaurant_code=${RC}`,
                                            {
                                                method: "POST",
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: ids
                                            }
                                        );                                        
                                    }
                                //}
                            }catch(_){}

                            fetch(
                                `${MIDD_URL}/api/v1/bcv/${is_for_close ? 'close_tickets' : 'to_retire'}?restaurant_code=${RC}`,
                                {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: ids
                                }
                            );
                        }
                    }
                }

            }
        }catch(_){}
    };

    const move_dish_next = async (join = false) => {

        const copy = [...(act_dishes ?? []).filter(f => !f.is_carry)];

        if(copy.length == 0) return;
        set_act_dishes([]);

        await change_dish_status(copy, null, false, true);

        // Check per status bidone e stato di consegna!
        ticket_manager_stepper(true);
        ticket_manager_stepper(false);

        const f_status = (STATUS ?? []).filter(item => item.status_enum == c_view);
        if(f_status && f_status.length > 0 && f_status[0].print_receipt == 1){
            const ip = localStorage.getItem('printer_ip_address');
            let join_to_send = [];
            if(ip){
                copy.forEach((item) => {

                    if(!join){
                        let arr = [
                            `TAVOLO ${item.table_code}`,
                            "************************",
                            `${item.quantity}x ${item.description}`,
                        ];
                        if(item.variations){
                            JSON.parse( item.variations ).forEach(v => {
                                arr.push('     ' + v.variant_name);
                            });
                        }
                        if(item.note){
                            arr.push('     ' + item.note);
                        }
                        arr.push("************************");
                        esc_pos(arr);
                    }else{
                        const index = join_to_send.findIndex(j => j.table == item.table_code);
                        if(index == -1){
                            let arr = [
                                `TAVOLO ${item.table_code}`, '************************', `${item.quantity}x ${item.description}`
                            ];
                            if(item.variations){
                                JSON.parse( item.variations ).forEach(v => {
                                    arr.push('     ' + v.variant_name);
                                });
                            }
                            if(item.note){
                                arr.push('     ' + item.note);
                            }
                            join_to_send.push({
                                table: item.table_code,    
                                description: arr
                            });
                        }else{
                            join_to_send[index].description.push(`${item.quantity}x ${item.description}`);
                            if(item.variations){
                                JSON.parse( item.variations ).forEach(v => {
                                    join_to_send[index].description.push('     ' + v.variant_name);
                                });
                            }
                            if(item.note){
                                join_to_send[index].description.push('     ' + item.note);
                            }
                        }
                    }
                });
                if(join && join_to_send.length > 0){
                    join_to_send.forEach(j => {
                        j.description.push("************************");
                        esc_pos(j.description);
                    });
                }
            }
        }

        const bc_pulse = new CustomEvent('bc:pulse', {detail: []});
        dispatchEvent(bc_pulse);
        set_in_modif(false);
        set_in_select_all(false);
        set_filter_for('display_dish');
        set_filter_id(null);
        set_act_dishes([]);

        //bcs.main_f(false, () => {
            //set_display_spin(false);
        //});


    };

    const  change_dish_status = async (item, force_status_to = null, call_main_f = false, is_massive = false) => {

        let filtered = [];
        if(!is_massive)
            filtered = (STATUS ?? []).filter(s => s.status_enum == item.status);
        else
            filtered = (STATUS ?? []).filter(s => s.status_enum == item[0].status);

        if(filtered && filtered.length > 0){

            try{
                
                const new_status = !force_status_to ? filtered[0].change_status_to : force_status_to;
                const massive_body = [];

                const now = new Date();
                const time = 
                    now.getFullYear()                                       + "-" + 
                    (now.getMonth() + 1).toString().padStart(2, '0')        + '-' + 
                    now.getDate().toString().padStart(2, '0')               + " " + 
                    now.getHours().toString().padStart(2, '0')              + ":" + 
                    now.getMinutes().toString().padStart(2, '0')            + ":" + 
                    now.getSeconds().toString().padStart(2, '0');

                if(is_massive){

                    item.forEach((c) => {

                        if(c.status == 'da_fare'){
                            const elapsed_minutes_in_to_do = (new Date() - new Date(c.timestamp));
                            c.time_in_to_do = Math.floor( ( (elapsed_minutes_in_to_do < 0 ? 0 : elapsed_minutes_in_to_do) / 1000) / 60 );
                        }

                        try{
                            if(localStorage.getItem('group_by_code') == "1"){
                                if(c.relateds && c.relateds.length > 0){
                                    c.relateds.forEach(r => {
                                        massive_body.push(
                                            {
                                                id                  : r.spring_id,
                                                restaurant_code     : RC,
                                                subscriber_code     : SC,
                                                bill_id             : c.bill_id,
                                                table_id            : c.table_code,
                                                unique_code         : r.id,
                                                status              : new_status,
                                                time_in_to_do       : c.time_in_to_do,
                                                product_description : r.description,
                                                quantity            : r.quantity,
                                                menu_composition    : c.menu_composition,
                                                variations          : c.variations,
                                                done                : c.done
                                            }
                                        );
                                    });
                                }
                            }
                        }catch(_){}

                        massive_body.push(
                            {
                                id                  : c.spring_id,
                                restaurant_code     : RC,
                                subscriber_code     : SC,
                                bill_id             : c.bill_id,
                                table_id            : c.table_code,
                                unique_code         : c.id,
                                status              : new_status,
                                time_in_to_do       : c.time_in_to_do,
                                product_description : c.description,
                                quantity            : c.quantity,
                                menu_composition    : c.menu_composition,
                                variations          : c.variations,
                                done                : c.done
                            }
                        );

                        // Ottieni data spostamento
                        c.moved_in_new_status_at = time;

                    });
                }else{
                    
                    if(item.status == 'da_fare'){
                        const elapsed_minutes_in_to_do = (new Date() - new Date(item.timestamp));
                        item.time_in_to_do = Math.floor( ( (elapsed_minutes_in_to_do < 0 ? 0 : elapsed_minutes_in_to_do) / 1000) / 60 );
                    }

                    item.moved_in_new_status_at = time;

                }

                const copy_of_all_dishes = [...all_dishes];
                let forOn;
                if(!is_massive){
                    forOn = [item];
                }else{
                    forOn = item;
                }

                const stat_nov = [...STAT_NOV];
                forOn.forEach((f) => {

                    if(f.work_in_progress){
                        const indexWp = WKP_NOV.findIndex(i => i.toString() == f.id.toString());
                        if(indexWp > -1){
                            WKP_NOV.splice(indexWp, 1);
                        }
                    }

                    const index = copy_of_all_dishes.findIndex(i => i.id.toString() == f.id.toString());
                    if(index > -1){
                        copy_of_all_dishes[index].status = new_status;
                        copy_of_all_dishes[index].work_in_progress = false;
                    }

                    const indexS = stat_nov.findIndex(s => s.id.toString() == f.id.toString());
                    if(indexS > -1){
                        stat_nov[indexS].status = new_status
                    }else{
                        stat_nov.push({id: f.id.toString(), status: new_status});
                    }

                });

                set_stat_nov(stat_nov);
                set_all_dishes(copy_of_all_dishes);

                const res_change_status = 
                    await fetch(
                        `${MIDD_URL}/api/v1/bcv/${!is_massive ? 'change_bill_status' : 'massive_change_bill_status'}`,
                        {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: 
                                !is_massive ?
                                    JSON.stringify(
                                        {
                                            id                  : item.spring_id,
                                            restaurant_code     : RC,
                                            subscriber_code     : SC,
                                            bill_id             : item.bill_id,
                                            table_id            : item.table_code,
                                            unique_code         : item.id,
                                            status              : new_status,
                                            time_in_to_do       : item.time_in_to_do,
                                            product_description : item.description,
                                            quantity            : item.quantity,
                                            menu_composition    : item.menu_composition,
                                            variations          : item.variations
                                        }
                                    )
                                :
                                    JSON.stringify(massive_body)
                        }
                    );

                if(res_change_status.status == 200){
                    const json_change_bill_status = await res_change_status.json();
                    if(json_change_bill_status.Result == "ERR"){
                        if(IN_DEBUG){
                            console.log("Error during change bill status");
                        }
                    }else{
                        //if(call_main_f)
                        //    bcs.main_f(false, () => {
                        //
                        //    });
                    }
                }

            }catch(_){}

        }

        set_number_of_dish( calculate_number_of_dishes() );
        set_act_dishes([]);
        const bc_pulse = new CustomEvent('bc:pulse', {detail: []});
        dispatchEvent(bc_pulse);

    };

    const delMsgToRead = () => {
        fetch(
            `http://217.160.159.28:9098/get_messages_to_read/${DEV_ID}`
        ).then(async (response) => {
            if(response.status == 200){
                const json = await response.json();
                if(json.success){
                    setMsgToRead(json.to_read ?? 0);
                }
            }
        });
    };

    useEffect(() => {

        if(!ALERT_RUN){
            start_runtime_alert();
            set_alert_run(true);
        }

        const evtForceRendering = () => {
            set_force_render(new Date().getTime());
        };

        f_render(evtForceRendering);

        const sidebar_extended = localStorage.getItem('sidebar_extended');
        if(sidebar_extended == "1"){
            const mainGrid = document.getElementsByClassName('main-grid');
            if(mainGrid && mainGrid.length > 0){
                const style = mainGrid[0].style;
                style.gridTemplateColumns = "160px auto 120px";
            }
        }

        if(STATUS && STATUS.length > 0){
            const filtered = STATUS.filter(item => item.is_start_view == 1);
            if(filtered.length > 0){
                set_c_view(filtered[0].status_enum);
            }
        }

        // Recupera tutti i reparti
        get_devices().then(async (res_devices) => {
            if(res_devices.status == 200){
                const json_devices = await res_devices.json();
                if(json_devices.Result == "OK"){
                    set_devices(json_devices.Devices);
                }
            }
        });

        // Timer alert
        if(localStorage.getItem('show_alert_timer') == "1"){
            setInterval(() => {
                timer -= 1;
                if(timer == 0){
                    timer = 120;
                }
                document.querySelector("#time-alert").innerHTML = timer.toString();
            }, 1000);
        }

        // Se usa la chat controlla i messaggi per il tuo reparto
        if(DEV_ID){
            set_del_msg_to_read(delMsgToRead);
            DEL_MSG_TO_READ();
        }

    }, []);

    return (
        <section class="main-grid"> 

            {
                displayChat && 
                    <Chat close={() => {
                        setDisplayChat(false);
                    }} removeToRead={(toRemove) => {
                        setMsgToRead(msgToRead - toRemove);
                    }}/>
            }

            <div id="next-alert-time" style={{
                position: "absolute",
                bottom: "20px",
                right: "20px",
                zIndex: "999999",
                background: "red",
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "10px",
                paddingBottom: "10px",
                color: "#fff",
                borderRadius: "10px",
                textAlign: "center",
                display: localStorage.getItem('show_alert_timer') == "1" ? "blocl" : "none"
            }}>
                Prossimo alert tra <span id="time-alert"></span> secondi
            </div>

            <div id="color_chooser" style={{display: "none"}}>
                <div style={{
                    position: "absolute",
                    top: "20px",
                    left: "20px",
                    zIndex: "99999",
                    alignItems: "center",
                    display: "flex",
                    background: "#0000007a",
                    padding: "10px",
                    gap: "20px",
                    borderRadius: "20px"
                }} >
                    <div key={`critical_command_color_0`} style={{color: "white", background: "#000", borderRadius: "1000px", width: "120px", height: "120px", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center"}} onClick={() => {
                        set_group_clr(null);
                        document.querySelector("#color_chooser").style.display = "none";
                        set_force_render(new Date().getTime());
                    }}>
                        Tutto
                    </div>
                    {
                        (JSON.parse(localStorage.getItem('critical_command')) ?? []).map((item, index) => 
                            <div key={`critical_command_color_${index}`} style={{color: "white", background: item.color, borderRadius: "1000px", width: "120px", height: "120px", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center"}} onClick={() => {
                                set_group_clr(item.color);
                                document.querySelector("#color_chooser").style.display = "none";
                                set_force_render(new Date().getTime());
                            }}>
                                <div>
                                    da {item.start_from} min
                                </div>
                                <div>
                                    a {item.end_to} min
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>

            <Alerts exitCallback={(delegate) => {
                
                set_del_alert(delegate);

                ////////////////////////////////
                // CHECK DEGLI ALERTS INIZIALI
                ///////////////////////////////
                setTimeout(() => {
                    
                    const drawer = document.getElementsByClassName("drawer")[0];

                    ////////////
                    // 1
                    ////////////
                    try{
                        if(localStorage.getItem('alert_screen') == "1"){
                            if(window.innerHeight < drawer.scrollHeight){
                                ALERTS.push("Attenzione, ci sono delle comande nella parte inferiore dello schermo!");
                            }
                        }
                    }catch(_){}

                    ////////////
                    // 2
                    ////////////
                    try{
                        if(localStorage.getItem('scroll_screen') == "1"){
                            if(window.innerHeight < drawer.scrollHeight){
                                drawer.scrollTop = drawer.scrollHeight;
                                ALERTS.push("E' stato effettuato uno scroll automatico verso il fondo dello schermo per far si che tu possa visualizzare tutte le comande disponbili!");
                            }
                        }
                    }catch(_){}

                    if(ALERTS.length > 0){
                        DEL_ALERT(new Date().getTime());
                    }

                }, 2000);

            }}/>

            <Msg/>
            <VaiAnim callbackVaiAnim={(func) => callbackVaiAnim = func}/>
            <Spin display={display_spin}/>  

            <div className="extra-settings">
                {
                    //<UpStatus/>
                }
                <div onClick={() => {                                
                    try{
                        if(!IS_FS){
                            document.querySelector('#root').requestFullscreen();
                            set_is_fs(true);
                        }else{
                            document.exitFullscreen();
                            set_is_fs(false);
                        }
                        set_force_render(new Date().getTime());
                    }catch(_){
                        alert("Full screen non supportato.")
                    }
                }} style={{cursor: "pointer"}}>
                    <i class="fas fa-expand-arrows-alt" style={{fontSize: "20px", color: "var(--bs-primary)"}}></i>
                </div>
                {
                    localStorage.getItem('use_department_chat') == "1" ? 
                        <div onClick={() => {
                            
                            setDisplayChat(true);

                        }} style={{display: "flex", gap: "5px", alignItems: "center", cursor: "pointer"}}>
                            <i class="far fa-comment-dots" style={{fontSize: "22px", color: "var(--bs-primary)"}}></i>
                            <div style={{borderRadius: "10000px", color: "#000", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "16px", fontWeight: "bold"}}>+{msgToRead}</div>
                        </div>
                    :
                        <></>
                }
                <div onClick={() => {
                    set_pl_audio(!PLAY_AUDIO);
                    set_force_render(new Date().getTime());
                }} style={{cursor: "pointer"}}>
                    <i class={PLAY_AUDIO ? "far fa-bell" : "far fa-bell-slash"} style={{fontSize: "20px", color: "var(--bs-primary)"}}></i>
                </div>
                <div onClick={() => {
                    set_in_select_all(false);
                    navigate('/setting');
                }} style={{cursor: "pointer"}}>
                    <i class="fas fa-cog" style={{fontSize: "20px", color: "var(--bs-primary)"}}></i>
                </div>
                <div style={{display: localStorage.getItem('display_vai_filter') == "1" ? "" : "none"}}>
                    <button className="btn btn-danger" id="btn_vai_con" style={{fontSize: "13px", width: "100px", fontWeight: "bold", textDecoration: filterOnlyVai ? "line-through" : "none"}} onClick={(e) => {
                        e.target.style.removeProperty('color');
                        e.target.style.removeProperty('background-color');
                        setFilterOnlyVai(!filterOnlyVai);
                    }}>
                        VAI
                    </button>
                </div>
                <div class="dropdown">
                    <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{fontSize: "13px"}}>
                        {
                            (document.querySelector('#range_grid_template') ? (document.querySelector('#range_grid_template') .value + "%")  : "0%")
                        }
                    </button>
                    <ul class="dropdown-menu">
                        <li style={{padding: "10px"}}>
                            <>
                                <input type="range" class="form-range" id="range_grid_template" onChange={(e) => {
                                    const new_var_range =`repeat(auto-fill, minmax(${e.target.value}%, 1fr))`;
                                    document.documentElement.style.setProperty('--grid-layout', new_var_range);
                                    
                                    if(localStorage.getItem('monitor') == 'dish'){
                                        localStorage.setItem('grid_layout_dish', new_var_range);
                                        localStorage.setItem('grid_layout_value_dish', e.target.value);
                                    }else{
                                        localStorage.setItem('grid_layout_table_command', new_var_range);
                                        localStorage.setItem('grid_layout_value_table_command', e.target.value);
                                    }
                                    set_force_render(new Date().getTime());

                                }} defaultValue={localStorage.getItem('monitor') == 'dish' ? localStorage.getItem('grid_layout_value_dish') : localStorage.getItem('grid_layout_value_table_command')}></input>
                            </>
                        </li>
                    </ul>
                </div>
                <div class="dropdown">
                    <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{fontSize: "13px"}}>
                        { (localStorage.getItem('monitor') == 'dish' ? "piatto" : localStorage.getItem('monitor') == 'table' ? "tavolo" : "comanda").toUpperCase() }
                    </button>
                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="#" onClick={() => {
                            localStorage.setItem('monitor', 'dish');
                            document.documentElement.style.setProperty('--grid-layout', localStorage.getItem('grid_layout_dish'));
                            document.querySelector('#range_grid_template').value = localStorage.getItem('grid_layout_value_dish');
                            window.location.href = "/";
                        }}>Piatto</a></li>
                        <li><a class="dropdown-item" href="#" onClick={() => {
                            localStorage.setItem('monitor', 'command');
                            document.documentElement.style.setProperty('--grid-layout', localStorage.getItem('grid_layout_table_command'));
                            document.querySelector('#range_grid_template').value = localStorage.getItem('grid_layout_value_table_command');
                            window.location.href = "/";
                        }}>Comanda</a></li>
                        <li><a class="dropdown-item" href="#" onClick={() => {
                            localStorage.setItem('monitor', 'table');
                            document.documentElement.style.setProperty('--grid-layout', localStorage.getItem('grid_layout_table_command'));
                            document.querySelector('#range_grid_template').value = localStorage.getItem('grid_layout_value_table_command');
                            window.location.href = "/";
                        }}>Tavolo</a></li>
                    </ul>
                </div>
                <div class="dropdown">
                    <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{fontSize: "13px"}}>
                        { 
                            (devices ?? []).filter(i => i.id == parseInt(localStorage.getItem('device'))).length > 0 ?  
                                (
                                    (devices ?? []).filter(i => i.id == parseInt(localStorage.getItem('device')))[0]['code']
                                        +
                                    ` (${number_of_dish})`
                                )
                            :
                                ""
                        }
                    </button>
                    <ul class="dropdown-menu">
                        {
                            (devices ?? []).map((item, index) => {
                                return (
                                    <li key={"device_" + index.toString()} onClick={() => {
                                        localStorage.setItem('device', item.id);
                                        set_dev_id(item.id);
                                        window.location.href = "/";
                                    }}>
                                        <a class="dropdown-item" href="#">{item.code}</a>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            </div>

            <div class="left">
                <div class="sidebar-title" onClick={() => {
                    set_filter_for(null);
                    set_filter_id(null);
                }}>TUTTI I PIATTI</div>
                <div class="sidebar">
                    <LDish 
                        status={c_view}
                        click_event={async (id) => {
                            
                            const monitor = localStorage.getItem('monitor');
                            const filtered = all_dishes.filter(i => i.status == c_view);
                            if( (monitor == 'command' || monitor == 'table') && filtered.length > 0){
                                const toTap = filtered.filter(i => i.ext_code == id.toString() && i.status == c_view);
                                set_act_dishes(toTap);
                            }

                            set_table_dish('display_dish');
                            set_filter_for('dish');
                            set_filter_id(id);
                            
                        }} 
                        dishes={ (all_dishes ?? []) } 
                        filter_for={filter_for} 
                        filter_id={filter_id}
                        remove_filter={() => {
                            set_act_dishes([]);
                            set_filter_for('display_dish');
                            set_filter_id(null);
                        }}
                    />
                </div>
            </div>

            <div class="center">
                {
                    //class={window.innerWidth >= 1300 ? "center-grid" : "center-grid-tablet"}
                }
                <div className="center-grid">
                    
                    <div class="tab-bar">
                        <div class="tabs">
                            <Bc_Tab opened={c_view} change_tab={(new_view) => {

                                set_filter_for('display_dish');
                                set_filter_id(null);
                                set_act_dishes([]);
                                set_c_view(new_view);
                                
                                const bc_pulse = new CustomEvent('bc:pulse', {detail: []});
                                dispatchEvent(bc_pulse);

                            }}/>
                        </div>
                    </div>

                    <div class="drawer">
                        {
                            display_summary ? 
                                <Summary 
                                    rows={summary_rows} 
                                    view={(STATUS ?? []).filter(item => item.status_enum == c_view)[0].status_label}
                                    set_all_dishes={(dishes, action) => {
                                        set_act_dishes(dishes);
                                        const bc_pulse = new CustomEvent('bc:pulse', {detail: [...dishes]});
                                        dispatchEvent(bc_pulse);
                                        set_display_summary(false);
                                        setTimeout(() => {
                                            switch(action){
                                                
                                                case "next": {
                                                    document.querySelector('#act_next').click();
                                                } break;

                                                case "next_and_join": {
                                                    document.querySelector('#act_next_and_join').click();
                                                } break;

                                                case "work_in_progress": {
                                                    document.querySelector('#act_work_in_progress').click();
                                                } break;

                                            }
                                        }, 250); 
                                    }}
                                />
                            :
                                <></>
                        }
                        <div className="backdrop" id="div_backdrop" style={{display: "none"}} onClick={(e) => {
                            e.target.style.display = "none";
                            if(display_summary){
                                set_display_summary(false);
                                return;
                            }
                            const list = document.getElementsByClassName('exploded');
                            if(list && list.length > 0){
                                list[0].classList.remove('exploded');
                            }
                        }}/>
                        <div class="drawer-container">
                        {
                            localStorage.getItem('monitor') == 'dish' ? // Vista piatti
                                (
                                    c_view ? 
                                        table_dish == 'display_dish' && filter_id ? 
                                            (all_dishes ?? []).filter(item => item.status == c_view.replace("view_", ""))
                                            .filter(item => (filter_id ? item.ext_code.toString() == filter_id.toString() : item))
                                            .map((item, index) => 
                                                <Dish item={item} key={"dish_" + item.id} tap={() => dish_tap(item)} active={is_dish_active(item)} double_click={async (new_status) => await change_dish_status(item, new_status, true)} clean_active_dishes={clean_active_dishes} callback_for_re_rendering={() => {
                                                    set_force_render(new Date().getTime());
                                                    set_number_of_dish( calculate_number_of_dishes() );
                                                }} txns={txns}/>
                                            )
                                        :
                                            table_dish == 'display_table' && filter_id ? 
                                                (all_dishes ?? []).filter(item => item.status == c_view.replace("view_", ""))
                                                .filter(item => (filter_id ? item.table_code.toString() == filter_id.toString() : item))
                                                .map((item, index) =>
                                                    <Dish item={item} key={"dish_" + item.id} tap={() => dish_tap(item)} active={is_dish_active(item)} double_click={async (new_status) => await change_dish_status(item, new_status, true)} clean_active_dishes={clean_active_dishes} callback_for_re_rendering={() => {
                                                        set_force_render(new Date().getTime());
                                                        set_number_of_dish( calculate_number_of_dishes() );
                                                    }} txns={txns}/>
                                                )
                                            :
                                                (all_dishes ?? []).filter(item => item.status == c_view.replace("view_", ""))
                                                .map((item, index) => {
                                                    return (
                                                        <Dish 
                                                            item={item} 
                                                            key={"dish_" + item.id} 
                                                            tap={() => dish_tap(item)} 
                                                            active={is_dish_active(item)} 
                                                            double_click={async (new_status) => await change_dish_status(item, new_status, true)} 
                                                            clean_active_dishes={clean_active_dishes}
                                                            callback_for_re_rendering={() => {
                                                                set_force_render(new Date().getTime());
                                                                set_number_of_dish( calculate_number_of_dishes() );
                                                            }}
                                                            txns={txns}
                                                        />
                                                    );
                                                })
                                                
                                    :
                                        <></>
                                )
                            :
                                txns && txns.length > 0 && c_view ? 
                                    (
                                        (localStorage.getItem('order_as_come') ?? '0') == "1" ?
                                                wrap_transaction_as_table_or_command(
                                                    txns, 
                                                    localStorage.getItem('monitor') == 'table' ? 'table' : 'command', 
                                                    filter_for, 
                                                    filter_id,
                                                    "by_id"
                                                )
                                            :
                                                wrap_transaction_as_table_or_command(
                                                    txns, 
                                                    localStorage.getItem('monitor') == 'table' ? 'table' : 'command', 
                                                    filter_for, 
                                                    filter_id,
                                                    "by_time"
                                                )
                                                .sort((a, b) => (!b.command_timer ? b.waiting_time : b.second_waiting_time) - (!a.command_timer  ? a.waiting_time : a.second_waiting_time))
                                    )
                                    .filter(i => (!filterOnlyVai ? (1 == 1) : (i.lines.findIndex(a => a.evasione_vai) > -1) ))
                                    .map((item, _index) => {

                                        try{
                                            if(localStorage.getItem('limit_command_for_view') == "1"){
                                                let view = parseInt(localStorage.getItem('command_for_view'));
                                                if(view){
                                                    if((_index + 1) > view){
                                                        return (<></>);
                                                    }
                                                }
                                            }
                                        }catch(e){}
                                        
                                        let extractedVaiCon = -1;
                                        const filteredDishes = item.lines.filter(l => l.status == c_view);
                                        
                                        // Mostrare senza attesa
                                        let displayWithoutWait = true;
                                        try{    
                                            const indexWithoutWait = item.lines.findIndex(i => i.status == c_view && !i.vai_con);
                                            displayWithoutWait = indexWithoutWait > -1;
                                        }catch(_){}

                                        //if(all_dishes.length > 0){
                                        //    console.log(all_dishes);
                                        //}

                                        let dishes_to_render = 
                                            all_dishes.filter( 
                                                d => 
                                                    (d.status == c_view)
                                                        && 
                                                    (d.table_code.toString() == item.id.toString() && d.id_ticket == item.id_ticket)
                                                        &&
                                                    (
                                                        localStorage.getItem('monitor') == 'table' ? 
                                                            (1 == 1)
                                                        :   
                                                            (
                                                                localStorage.getItem('group_command_for_minute') == "1" ? 
                                                                    (d.date_get == item.date_get)
                                                                :
                                                                    ( (d.nsessione == item.nsessione) || d.is_carry )
                                                            )
                                                    )
                                            );

                                        // Calcola numero di comande..
                                        let extracted_dates = [];
                                        let dish_to_do = 0;
                                        (dishes_to_render ?? []).forEach((item) => {
                                            if(extracted_dates.findIndex(i => i == item.date_get) == -1){
                                                extracted_dates.push(item.date_get);
                                            }
                                            dish_to_do += parseInt(item.quantity);
                                        });

                                        if(localStorage.getItem('monitor') == 'table' || localStorage.getItem('monitor') == 'command'){

                                            const hasVaiCon = dishes_to_render.findIndex(i => i.vai_con) > -1;
                                            if(hasVaiCon){
                                                const grouped = groupBy(dishes_to_render, "vai_con");
                                                grouped.forEach(f => {
                                                    if(f.key != "null"){
                                                        for(let i = 0; i < f.values.length; i++){
                                                            if(!f.values[0].force_display_vai_con_to_false){
                                                                if(i == 0){
                                                                    f.values[i].display_vai_con = true;
                                                                }else{
                                                                    f.values[i].force_display_vai_con_to_false = true;
                                                                    f.values[i].display_vai_con = false;
                                                                }
                                                            }else{
                                                                f.values[i].display_vai_con = false;
                                                            }
                                                        }
                                                    }
                                                });
                                                const newDishes = [];
                                                grouped.forEach(f => {
                                                    f.values.forEach(v => newDishes.push(v));
                                                });
                                                dishes_to_render = [...newDishes];
                                            }

                                            // Organizza i carry
                                            let _copy = [];
                                            for(let noC = 0; noC < dishes_to_render.length; noC++){
                                                if(!dishes_to_render[noC].is_carry)
                                                    _copy.push(dishes_to_render[noC])
                                            }
                                            for(let yesC = 0; yesC < dishes_to_render.length; yesC++){
                                                if(dishes_to_render[yesC].is_carry)
                                                    _copy.push(dishes_to_render[yesC])
                                            }
                                            if(_copy && _copy.length > 0){
                                                dishes_to_render = [..._copy];
                                            }

                                        }

                                        // Ordina per codice
                                        try{
                                            if(localStorage.getItem('order_in_command_by_external_code') == "1"){
                                                //const newLines = item.lines.sort((a, b) => parseInt(a.ext_code ?? 0) - parseInt(b.ext_code ?? 0));
                                                //item.lines = [...newLines];
                                                // Ordina sulla base delle uscite
                                                let exits = [];
                                                dishes_to_render.forEach(v => {
                                                    if(!exits.includes(v.vai_con)){
                                                        exits.push(v.vai_con);
                                                    }
                                                });
                                                let groups = [];
                                                exits.forEach(e => {
                                                    groups.push(
                                                        {
                                                            "exit": e,
                                                            "lines": dishes_to_render.filter(d => d.vai_con == e).sort((a, b) => parseInt(a.ext_code ?? 0) - parseInt(b.ext_code ?? 0))
                                                        }
                                                    );
                                                });
                                                
                                                let newLines = [];
                                                groups.forEach(g => {
                                                    let isFirst = true;
                                                    g.lines.forEach(
                                                        (gg) => {
                                                            //console.log(gg);
                                                            if(isFirst && gg.vai_con != "" && gg.vai_con){
                                                                gg.display_vai_con = true;
                                                                isFirst = false;
                                                            }else{
                                                                gg.display_vai_con = false;
                                                            }
                                                            newLines.push(gg);
                                                        }
                                                    );
                                                });
                                                if(newLines.length > 0){ // Separa riporti
                                                    const noReports = newLines.filter(n => !n.is_carry);
                                                    const reports   = newLines.filter(n => n.is_carry);
                                                    newLines = [...noReports, ...reports];
                                                }

                                                // Ordinamento in caso di menu
                                                try{
                                                    const hasMenu = newLines.findIndex(f => f.menu_code && !f.menu_parent) > -1;
                                                    if(hasMenu){
                                                        
                                                        // Ottieni lista già ordinata senza subarticoli
                                                        let replaceLines = newLines.filter(f => !f.menu_parent || (!f.menu_parent && f.menu_code));
                                                        
                                                        const noMenus   = replaceLines.filter(r => !r.menu_code);
                                                        const menus     = replaceLines.filter(r => r.menu_code);

                                                        replaceLines = [...noMenus, ...menus];

                                                        // Dalle linee principali ottieni i riferimenti
                                                        let mapRef = [];
                                                        newLines.filter(f => f.menu_parent)
                                                        .forEach((s) => {
                                                            const menuParent = s.menu_parent;
                                                            const index = mapRef.findIndex(m => m.parent == menuParent);
                                                            if(index == -1){
                                                                mapRef.push(
                                                                    {
                                                                        parent: menuParent,
                                                                        items: [s]
                                                                    }
                                                                );
                                                            }else{
                                                                mapRef[index].items.push(s);
                                                            }
                                                        });
                                                        let newLines_ = [];
                                                        replaceLines.forEach((r) => {
                                                            newLines_.push(r);
                                                            if(r.menu_code){
                                                                const index_ = mapRef.findIndex((m) => m.parent == r.menu_code);
                                                                if(index_ > -1){
                                                                    mapRef[index_].items.forEach((i) => {
                                                                        newLines_.push(i);
                                                                    }); 
                                                                }
                                                            }
                                                        });
                                                        newLines = newLines_;

                                                    }
                                                }catch(_){}

                                                dishes_to_render = newLines;
                                                
                                            }
                                        }catch(_){}


                                        return (
                                            filteredDishes.length > 0 ?     
                                                <div class={`table-container card ${item.critical_status ? "critical" : ""}`} key={`table_${item.id}_${_index}`} id={`table_${item.id}_${_index}`}>
                                                    <button className="btn btn-danger btn-exploded" style={{display: "none", right: "555px"}} onClick={() => {
                                                        document.querySelector("#div_backdrop").click();
                                                    }}>Chiudi</button>
                                                    <button className="btn btn-primary btn-exploded" style={{display: "none", right: "395px"}} onClick={() => {
                                                        
                                                        set_act_dishes([]);
                                                        const bc_pulse = new CustomEvent('bc:pulse', {detail: []});
                                                        dispatchEvent(bc_pulse);

                                                    }}>Deseleziona tutto</button>
                                                    <button className="btn btn-primary btn-exploded" style={{display: "none", right: "255px"}} onClick={() => {
                                                        
                                                        const copy = [...act_dishes];
                                                        (dishes_to_render ?? []).forEach(i => {
                                                            copy.push(i);
                                                        });
                                                        set_act_dishes(copy);
                                                        const bc_pulse = new CustomEvent('bc:pulse', {detail: copy});
                                                        dispatchEvent(bc_pulse);

                                                    }} >Seleziona tutto</button>
                                                    <button className="btn btn-primary btn-exploded" style={{display: "none", right: "120px"}} onClick={() => {
                                                        document.querySelector('#act_work_in_progress').click();
                                                    }}>In lavorazione</button>
                                                    <button className="btn btn-primary btn-exploded" style={{display: "none", right: "15px"}} onClick={() => {
                                                        document.querySelector(`#table_${item.id}_${_index}`).classList.remove('exploded');
                                                        document.querySelector('#div_backdrop').style.display = "none";
                                                    }} >Conferma</button>
                                                    <div class="card-header">
                                                        <div class="table-container-name" style={{display: "flex", justifyContent: "space-between"}}>
                                                            <div style={{color: "var(--green)", fontWeight: "bold"}}>{item.id_ticket ? <><i class="fas fa-file-invoice-dollar"></i>&nbsp;</> : <></>}TAVOLO {item.id}{item.cmd_ticket && item.cmd_ticket != "0" ? ` (Num.${item.cmd_ticket})` : ""}</div>
                                                            <div style={{display: "flex", gap: "15px"}}>
                                                                <div style={{color: "red"}}>
                                                                    <i class="fas fa-clock" aria-hidden="true"></i>&nbsp;{parseInt(item.waiting_time)}'
                                                                </div>
                                                                {
                                                                    item.command_timer ? 
                                                                        <div style={{color: "var(--green)"}}><i class="fas fa-clock" aria-hidden="true"></i>&nbsp;{parseInt(item.second_waiting_time)}'</div> 
                                                                    :
                                                                        <></>
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            dishes_to_render && dishes_to_render.length > 0 && dishes_to_render[0].code_waiter ? 
                                                                <div class="table-container-data" style={{display: "flex", justifyContent: "space-between", textAlign:"start"}}><div style={{color: "#545454", fontWeight: "500"}}>Cameriere:  {dishes_to_render[0].desc_waiter}</div></div>
                                                            :
                                                                <></>
                                                        }
                                                        {
                                                            //<div class="table-container-data" style={{display: "flex", justifyContent: "space-between", textAlign:"start"}}><div style={{color: "#545454", fontWeight: "500"}}>Cliente: Luigi Verdi</div></div> 
                                                        }
                                                        {
                                                            localStorage.getItem('monitor') == 'table' ? 
                                                                <div class="table-container-data" style={{display: "flex", justifyContent: "space-between", textAlign:"start"}}><div style={{color: "#545454", fontWeight: "500"}}>N.comande:  {extracted_dates.length}</div></div>
                                                            :
                                                                <></>
                                                        }
                                                        {
                                                            localStorage.getItem('monitor') == 'command' ? 
                                                                <div style={{display: "flex", justifyContent: "space-between", color: "#545454", fontWeight: "500"}}>
                                                                    <div>N.piatti: {dish_to_do}</div>
                                                                    <div>N.comanda: {item.bill_id} {localStorage.getItem('group_command_for_minute') !== "1" ? `/${item.nsessione ?? ""}` : ""}</div>
                                                                </div>
                                                            :
                                                                <>
                                                                </>
                                                        }
                                                        {
                                                            localStorage.getItem('delivery_take_away') == "1" && item.is_delivery ? 
                                                                <>
                                                                    <div style={{display: "flex", justifyContent: "space-between", color: "#545454", fontWeight: "500"}}>
                                                                        <div className="pulse" style={{color: "var(--green)"}}>{item.delivery_type ?? ""} {item.hour_delivery ? `(${item.hour_delivery})` : ""}</div>
                                                                        <div>{item.delivery_customer ?? ""}</div>
                                                                    </div>
                                                                </>
                                                            :
                                                                <></>
                                                        }

                                                        <div class="table-container-data" style={{display: "flex", justifyContent: "space-between", textAlign:"start", color: "#545454", fontWeight: "500"}}>
                                                            <div>N.coperti: {item.number_of_covers}</div>
                                                            <div onClick={() => {
                                                                
                                                                if(document.getElementsByClassName('exploded').length > 0) return;

                                                                document.querySelector('#div_backdrop').style.display = "";
                                                                document.querySelector(`#table_${item.id}_${_index}`).classList.add('exploded');
                                                            }} style={{cursor: "pointer"}}>
                                                                <i class="fas fa-expand-arrows-alt" style={{fontSize: "20px"}}></i>
                                                            </div>
                                                        </div>
                                                        
                                                        <div style={{width: "100%", justifyContent: "start", marginTop: "5px", display: localStorage.getItem('next_command') == "1" ? "flex" : "none"}}>
                                                            <button className="btn btn-primary" style={{width: "100px"}} onClick={() => {
                                                                
                                                                set_act_dishes([]);
                                                                const items = [];
                                                                dishes_to_render.filter(d => !d.is_carry).forEach(d => {
                                                                    items.push(d);
                                                                });
                                                                set_act_dishes(items);
                                                                setTimeout(() =>{
                                                                    document.querySelector('#act_next').click();
                                                                }, 10);

                                                            }}>Avanti</button>
                                                        </div>

                                                    </div>
                                                    <div class="card-body">
                                                        <div class="accordion" id="accordionPanelsStayOpenExample">
                                                            <div style={{marginLeft: "10px", color: "red", fontWeight: "bold", fontSize: "16px", borderRadius: "10px", marginBottom: "2px", display: displayWithoutWait ? "" : "none"}} onClick={() => {            
                                                                
                                                                const filtered = dishes_to_render.filter(i => !i.vai_con && i.status == c_view);
                                                                const copy = [...act_dishes];
                                                                
                                                                let toActive = true;
                                                                let dishes = [];

                                                                filtered.forEach(i => {
                                                                    const index = copy.findIndex(c => c.id == i.id);
                                                                    if(index != -1){
                                                                        toActive = false;
                                                                    }
                                                                    dishes.push(i);
                                                                });

                                                                dishes.forEach(d => {
                                                                    if(toActive){
                                                                        copy.push(d);
                                                                    }else{
                                                                        const index = copy.findIndex(i => i.id == d.id);
                                                                        if(index > -1){
                                                                            copy.splice(index, 1);
                                                                        }
                                                                    }
                                                                });

                                                                set_act_dishes(copy);

                                                            }}>&nbsp;&nbsp;-- SENZA ATTESA --</div>
                                                            {
                                                                (dishes_to_render ?? []).map((item, index) => {

                                                                    // Ottieni il primo riporto, se esiste
                                                                    const carry = dishes_to_render.filter(i => i.is_carry);
                                                                    let firstIdRep = -1;
                                                                    if(carry.length > 0){
                                                                        firstIdRep = carry[0].id;
                                                                    }

                                                                    const eye = 
                                                                        !NOREP.includes(item.bill_id) ? 
                                                                            <i class="far fa-eye"></i>
                                                                        :
                                                                            <i class="far fa-eye-slash"></i>;

                                                                    if(item.is_carry){
                                                                        extractedVaiCon++;
                                                                    }

                                                                    //if(item){
                                                                    //    console.log(item.description, item.vai_con != "" ? item.vai_con : "SEGUE-->0");
                                                                    //}

                                                                    return (
                                                                        <>
                                                                            {
                                                                                item.is_carry && extractedVaiCon == 0 ? 
                                                                                    <p style={{color: "white", marginLeft: "10px", fontSize: "15px", marginTop: "10px", background: "var(--green)", borderRadius: "10px", width: "130px", textAlign: "center", paddingLeft: "15px", paddingRight: "15px"}} onClick={() => {
                                                                                        
                                                                                        // Toggle visibilità comanda
                                                                                        const copy = [...NOREP];
                                                                                        const index = (copy ?? []).findIndex(r => r.toString() == item.bill_id);
                                                                                        if(index == -1){
                                                                                            copy.push(item.bill_id);
                                                                                        }else{
                                                                                            copy.splice(index, 1);
                                                                                        }
                                                                                        set_noreps(copy);
                                                                                        set_force_render(new Date().getTime());

                                                                                    }}>RIPORTI&nbsp;&nbsp;{eye}</p>
                                                                                :
                                                                                    <></>
                                                                            }
                                                                            <Dish 
                                                                                item={item} 
                                                                                key={"dish_" + item.id} 
                                                                                tap={() => dish_tap(item)} 
                                                                                active={is_dish_active(item)} 
                                                                                double_click={async (new_status) => await change_dish_status(item, new_status, true)} 
                                                                                clean_active_dishes={clean_active_dishes}
                                                                                callback_for_re_rendering={() => {
                                                                                    set_force_render(new Date().getTime());
                                                                                    set_number_of_dish( calculate_number_of_dishes() );
                                                                                }}
                                                                                txns={txns}
                                                                                multiple_taps={(items) => {

                                                                                    const copy = [...act_dishes];
                                                                                    let toActive = true;
                                                                                    let dishes = [];

                                                                                    items.forEach(i => {
                                                                                        const index = copy.findIndex(c => c.id == i.id);
                                                                                        if(index > -1){
                                                                                            toActive = false;
                                                                                        }
                                                                                        dishes.push(i);
                                                                                    });

                                                                                    dishes.forEach(d => {
                                                                                        if(toActive){
                                                                                            copy.push(d);
                                                                                        }else{
                                                                                            const index = copy.findIndex(i => i.id == d.id);
                                                                                            if(index > -1){
                                                                                                copy.splice(index, 1);
                                                                                            }
                                                                                        }
                                                                                    });
                                                                                    
                                                                                    set_act_dishes(copy);
                                                                                }}
                                                                            />
                                                                        </>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                                <></>
                                        );
                                    })
                                :
                                    <></>
                        }
                        </div>
                    </div>

                    <div class="controller">
                        <div class="controller-buttons">
                            {
                                //c_view !== "da_fare" && act_dishes.length > 0 ? 
                                    <Act click_event={async () => {
                                        
                                        if(c_view == "da_fare"){
                                            return;
                                        }
                                        const indexStatus = (STATUS ?? []).findIndex(i => i.change_status_to == c_view);
                                        if(indexStatus > -1){
                                            change_dish_status(act_dishes, STATUS[indexStatus].status_enum, false, true)
                                            .then(res => {
                                                //bcs.main_f(false);
                                            });
                                        }

                                    }} description="«« Indietro"/>
                                //:
                                //    <></>
                            }
                            <Act click_event={() => {

                                if(c_view && all_dishes){
                                    const copy = [];
                                    let filtered_dishses = all_dishes.filter(item => item.status == c_view && item.work_in_progress);
                                    if(filter_id){ // Filtra per tavolo
                                        filtered_dishses = filtered_dishses.filter(item => item.table_code == filter_id);
                                    }
                                    if(filtered_dishses.length == 0){ // Nessun prodotto in lavorazione, seleziona tutti i prodotti..
                                        filtered_dishses = all_dishes.filter(item => item.status == c_view);
                                    }
                                    filtered_dishses.forEach((item) => copy.push(item));
                                    const bc_pulse = new CustomEvent('bc:pulse', {detail: copy});
                                    dispatchEvent(bc_pulse);
                                    set_act_dishes(copy);
                                }


                            }} description={"Seleziona tutto"}/>
                            <Act click_event={() => {
                                set_in_select_all(false);
                                set_act_dishes([]);
                                const bc_pulse = new CustomEvent('bc:pulse', {detail: []});
                                dispatchEvent(bc_pulse);
                            }} description={"Deseleziona tutto"}/>
                            {
                                localStorage.getItem('enabled_in_work_button') == '1' ?
                                    <Act click_event={async () => {

                                        const index = act_dishes.findIndex(item => !item.work_in_progress);
                                        if(index == -1){
                                            const copy = [...WKP_NOV];
                                            const copy_dishes = [...all_dishes];
                                            act_dishes.forEach((act) => {
                                                const wk_index = copy.findIndex(w => w == act.id);
                                                if(wk_index > -1){
                                                    copy.splice(wk_index, 1);
                                                }
                                                const cd_index = copy_dishes.findIndex(item => item.id == act.id);
                                                if(cd_index > -1){
                                                    copy_dishes[cd_index].work_in_progress = false;
                                                }
                                                remove_from_work_in_progress(act.id);
                                            });
                                            set_wkp_nov(copy);
                                            set_all_dishes(copy_dishes);
                                            set_act_dishes([]);

                                            const bc_pulse = new CustomEvent('bc:pulse', {detail: []});
                                            set_act_dishes([]);
                                            dispatchEvent(bc_pulse);
                                            set_in_modif(false);

                                        }

                                    }} description="Rimuovi da lavorazione"/>
                                :
                                    <></>
                            }
                            {
                                //act_dishes.length > 0 ? 
                                    <>
                                        {
                                            //(act_dishes ?? []).filter(item => item.work_in_progress).length == 0 && c_view == "da_fare" && localStorage.getItem('enabled_in_work_button') == '1' ?
                                            localStorage.getItem('enabled_in_work_button') == '1' ?
                                                <Act click_event={async () => {

                                                    //set_display_spin(true);

                                                    set_in_select_all(false);

                                                    const wk = [...WKP_NOV];
                                                    const copy_of_all_dishes = [...all_dishes];

                                                    for (let dish of act_dishes){
                                                        const indexAllD = copy_of_all_dishes.findIndex(i => i.id.toString() == dish.id.toString());
                                                        copy_of_all_dishes[indexAllD].work_in_progress = true;
                                                        //dish.work_in_progress = true;
                                                        wk.push(dish.id);
                                                        save_work_in_progress(dish.id);
                                                    }

                                                    const copy = [...copy_of_all_dishes];
                                                    set_all_dishes(copy);
                                                    set_wkp_nov(wk);

                                                    const bc_pulse = new CustomEvent('bc:pulse', {detail: []});
                                                    set_act_dishes([]);
                                                    dispatchEvent(bc_pulse);
                                                    set_in_modif(false);

                                                    //bcs.main_f(false, () => {
                                                        //set_display_spin(false);
                                                    //});

                                                }} description="In lavorazione" id="act_work_in_progress"/>
                                            :
                                                <></>
                                            //:
                                            //    <></>
                                        }
                                        {
                                            //act_dishes && act_dishes.length == 1 ? 
                                            localStorage.getItem('monitor') == 'dish' ? 
                                                <Act click_event={async () => {
                                                    
                                                    const filtered = act_dishes.filter(item => item.ext_code);
                                                    if(filtered.length == 0) return;
                                                    const equals = [];

                                                    filtered.forEach((item) => {
                                                        const _eq = all_dishes.filter(i => i.ext_code == item.ext_code && i.status == item.status && i.id !== item.id);
                                                        if(_eq && _eq.length > 0){
                                                            _eq.forEach(e => equals.push(e));
                                                        }
                                                    });

                                                    const __act_dishes = [...act_dishes, ...equals];
                                                    set_act_dishes(__act_dishes);

                                                    set_table_dish('display_dish');
                                                    set_filter_for('dish');
                                                    set_filter_id(act_dishes[0].ext_code);

                                                }} description="Seleziona uguali"/>
                                            :
                                                <></>
                                            //:
                                            //    <></>
                                        }
                                        {
                                            localStorage.getItem('monitor') == 'dish' ? 
                                                <Act click_event={async () => {

                                                    if(act_dishes.length == 1){
                                                        const filtered = all_dishes.filter(item => act_dishes[0].bill_id == item.bill_id && item.id !== act_dishes[0].id && item.status == act_dishes[0].status);
                                                        if(filtered.length > 0){
                                                            const new_act_dishes = [act_dishes[0]];
                                                            filtered.forEach((i) => new_act_dishes.push(i));
                                                            set_act_dishes(new_act_dishes);
                                                        }
                                                    }

                                                }} description="Seleziona per comanda"/>
                                            :
                                                <></>
                                        }
                                        {
                                            localStorage.getItem('monitor') == 'command' ? 
                                                <Act click_event={() => {

                                                    const filtered = all_dishes.filter(item => item.status == c_view);
                                                    const group = [];
                                                    filtered.forEach(f => {
                                                        const index = group.findIndex(g => g.ext_code == f.ext_code);
                                                        if(index > -1){
                                                            group[index].quantity = group[index].quantity + parseInt(f.quantity);
                                                            group[index].dishes.push(f);
                                                        }else{
                                                            group.push({
                                                                description: f.description,
                                                                ext_code: f.ext_code,
                                                                quantity: parseInt(f.quantity),
                                                                dishes: [f]
                                                            });
                                                        }
                                                    });

                                                    if(group.length > 0){
                                                        document.querySelector('#div_backdrop').style.display = "";
                                                        set_display_summary(true);
                                                        set_summary_rows(group);
                                                    }

                                                }} description="Riepilogo"/>
                                            :
                                                <></>
                                        }
                                        {
                                            localStorage.getItem('active_critical_command') == "1" ? 
                                                <Act click_event={() => {

                                                    // Fai scegliere il colore
                                                    document.querySelector("#color_chooser").style.display = "block";
                                                    

                                                }} description={"Raggruppa per colore"}></Act>
                                            :
                                                <></>
                                        }
                                        <Act click_event={async () => {
                                            move_dish_next(true).then(res => {
                                                
                                            });
                                        }} description="Unisci e avanti" id="act_next_and_join"/>
                                        <Act click_event={async () => {
                                            move_dish_next().then(res => {
                                                
                                            });
                                        }} description="Avanti »»" id="act_next"/>
                                    </>
                                //:
                                //    <></>
                            }
                        </div>
                    </div>

                </div>
            </div>

            <div class="right">
                <div class="sidebar-title" onClick={() => {
                    set_filter_for(null);
                    set_filter_id(null);
                }}>TUTTI I TAVOLI</div>
                <div class="sidebar">
                    <LTable 
                        status={c_view}
                        txns={txns}
                        tables={(all_tables ?? []) }
                        filter_for={filter_for} 
                        filter_id={filter_id}
                        hookCommands={hookCommands}
                        click_event={(id) => {
                            set_table_dish('display_table');
                            set_filter_for('table');
                            set_filter_id(id);
                        }} 
                        remove_filter={() => {
                            set_filter_for('display_dish');
                            set_filter_id(null);
                        }}
                        all_dishes_change={all_dishes}
                    />
                </div>
            </div>

            <ToastContainer/>

        </section>
    );

};

export default View2;